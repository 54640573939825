<template>
  <div>
    <b-modal
      id="modal-delete-potongan"
      size="md"
      centered
      title="Perhatian"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <p>Apakah anda yakin akan menghapus data ini ?</p>

      <template #modal-footer>
        <b-button
          variant="primary"
          @click="hapus()"
        >
          {{ $store.state.form.ya }}
        </b-button>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-delete-potongan')"
        >
          {{ $store.state.form.tidak }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
export default {
  name: "modalDelete",
  props: ["data"],
  data() {
    return {
      busy:false,
      button:"Hapus"
    };
  },
  methods: {
    hapus() {
      let vm = this;
      vm.busy = true
      vm.button = "Mohon Tunggu"
      axios
        .post(ipBackend + "masterPotongan/delete", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Hapus";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MENGHAPUS DATA MASTER POTONGAN",
              showing: true,
            });
            vm.$bvModal.hide("modal-delete-potongan");
          } else {
            vm.button = "Hapus";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Hapus";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
