<template>
  <div>
    <b-modal
      id="modal-input-potongan"
      size="lg"
      centered
      title="Tambah Data Potongan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Nama Potongan" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('namaPotongan')"
            v-model="$v.dataPotongan.namaPotongan.$model"
            @keydown.enter.prevent="simpan()"
          ></b-form-input>
        </b-form-group>
      </b-form>

      <b-form>
        <b-form-group label="Jenis Potongan" label-cols-md="3">
          <b-form-select
            :options="jenis"
            :state="checkIfValid('jenisPotongan')"
            v-model="$v.dataPotongan.jenisPotongan.$model"
            @keydown.enter.prevent="simpan()"
          ></b-form-select>
        </b-form-group>
      </b-form>

      <b-form>
        <b-form-group label="Nominal Potongan" label-cols-md="3">
          <b-form-input
            type="number"
            :disabled="dataPotongan.jenisPotongan == 'Tidak Tetap'"
            :state="checkIfValid('nominalPotongan')"
            v-model="$v.dataPotongan.nominalPotongan.$model"
            :placeholder="convert(dataPotongan.nominalPotongan)"
            @keydown.enter.prevent="simpan()"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-input-potongan')"
        >
          {{ $store.state.form.batal }}
        </b-button>
        <b-button variant="primary" @click="simpan()" :disabled="!isValid"
          >Simpan</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  name: "modalInput",
  props: ["kategori", "unit"],
  data() {
    return {
      dataPotongan: {
        namaPotongan: "",
        nominalPotongan: 0,
        jenisPotongan: "",
      },
      jenis: [
        { value: "", text: "Pilih Jenis Potongan" },
        { value: "Tetap", text: "Tetap" },
        { value: "Tidak Tetap", text: "Tidak Tetap" },
      ],
      selected: null,
      busy: false,
      button: "Simpan",
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataPotongan, null, 4);
    },
    isValid() {
      return !this.$v.dataPotongan.$invalid;
    },
    isDirty() {
      return this.$v.dataPotongan.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataPotongan: {
      namaPotongan: {
        required,
      },
      jenisPotongan: {
        required,
      },
      nominalPotongan: {
        required: requiredIf(function () {
          return this.dataPotongan.jenisPotongan == "Tetap";
        }),
        numeric,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      // console.log(fieldName)
      const field = this.$v.dataPotongan[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    convert(x) {
      if (x) {
        return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
    simpan() {
      let vm = this;
      if (vm.isValid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        axios
          .post(ipBackend + "masterPotongan/register", vm.dataPotongan)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.reset();
              vm.$emit("alert", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN MASTER POTONGAN",
                showing: true,
              });
              this.$bvModal.hide("modal-input-potongan");
              this.reset();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alert", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    reset() {
      this.$v.$reset();
      this.dataPotongan = {
        namaPotongan: "",
        nominalPotongan: "",
        jenisPotongan: "",
      };
    },
  },
};
</script>
